<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    hide-default-footer
    class="elevation-1"
  >
    <template v-slot:[`item.actions`]>
      <v-btn
        icon
        color="primary"
      >
        <v-icon small>fas fa-eye</v-icon>
      </v-btn>
    </template>

    <template v-slot:[`item.order_status`]="{ item }">
      <StatusChip
        :status="orderStatus"
        :currentStatus="item.order_status"
      />
    </template>
  </v-data-table>
</template>

<script>
import StatusChip from '@/components/data-table/StatusChip'
export default {
  components: {
    StatusChip,
  },
  data() {
    return {
      headers: [
        {
          text: 'N°',
          align: 'start',
          value: 'id',
        },
        { text: 'Status', value: 'order_status', align: 'center' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'Data de criação', value: 'created_at', align: 'end' },
        { text: 'Ações', value: 'actions', align: 'end' },
      ],
      items: [
        // {
        //   id: '145',
        //   order_status: 4,
        //   total: 'R$ 487,50',
        //   created_at: '15/11/2020',
        // },
      ],
      orderStatus: [
        { value: 1, text: 'Pedido Emitido', color: 'blue-grey' },
        { value: 2, text: 'Aprovação de cadastro', color: 'indigo' },
        { value: 3, text: 'Aprovação de pedido', color: 'cyan' },
        { value: 4, text: 'Compras', color: 'deep-purple' },
        { value: 5, text: 'Pedido finalizado', color: 'success' },
        { value: 6, text: 'Arquivado', color: 'greyy' },
        { value: 7, text: 'Cancelado', color: 'error' },
      ],
    }
  },
}
</script>
