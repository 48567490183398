<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    hide-default-footer
    class="elevation-1"
    :loading="isLoading"
    loading-text="Carregando dados, por favor aguarde"
    no-results-text="Nenhum resultado encontrado"
    no-data-text="Nenhum dado encontrado"
  >
    <template v-slot:[`item.budget_status`]="{ item }">
      <StatusChip
        v-if="item"
        :status="budgetStatus"
        :currentStatus="item.budget_status"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        icon
        color="primary"
        @click="showPDF(item)"
        :loading="loadingBudget"
      >
        <v-icon small>fas fa-eye</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import StatusChip from '@/components/data-table/StatusChip'
export default {
  components: {
    StatusChip,
  },
  data() {
    return {
      budgetStatus: [
        { value: 90, text: 'Aguardando', color: 'blue-grey' },
        { value: 1, text: 'Enviado', color: 'cyan' },
        { value: 2, text: 'Aprovado', color: 'success' },
        { value: 3, text: 'Reprovado', color: 'error' },
        { value: 4, text: 'Abrir CRM', color: 'warning' },
      ],
      headers: [
        {
          text: 'Orçamento n°',
          align: 'start',
          value: 'id',
        },
        { text: 'Status', value: 'budget_status', align: 'center' },
        { text: 'Data de criação', value: 'created_at', align: 'start' },
        { text: 'Ações', value: 'actions', align: 'start' },
      ],
      isLoading: false,
      loadingBudget: false,
      items: [],
    }
  },
  methods: {
    getBudgets() {
      this.$api
        .post('budget/client', {
          client_id: this.$cookies.get('id'),
        })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showPDF(item) {
      this.loadingBudget = true
      let company_name = item.company.replace(' ', '_')
      this.$api
        .post(`budget/seepdf`, {
          id: item.id,
          company_name: company_name,
          client_company_name: item.client_company_name,
        })
        .then((res) => {
          window.open(
            `${this.$store.state.imagePath}${res.data.data}`,
            '_blank'
          )
          this.loadingBudget = false
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao gerar PDF deste orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.loadingBudget = false
        })
    },
  },
  mounted() {
    this.getBudgets()
  },
}
</script>
